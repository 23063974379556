import { Fragment, useState } from "react";
import { Modal, ModalHeader, Button, ModalFooter } from "reactstrap";

import backendService from "../services/backend.service";

type DeleteModalState = {
  id: string;
  reset: () => void;
};

const ItemDeleteModal = ({ id, reset }: DeleteModalState) => {
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const deleteProject = (id: string) => {
    backendService.delete(id).then(() => {
      reset();
      toggle();
    });
  };

  return (
    <Fragment>
      <Button
        outline
        onClick={() => toggle()}
        style={{ minWidth: "60px" }}
        title="Delete this item"
      >
        ❌
      </Button>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          Do you really want to delete the item?
        </ModalHeader>

        <ModalFooter>
          <Button type="button" onClick={() => toggle()}>
            Cancel
          </Button>
          <Button
            type="button"
            color="primary"
            onClick={() => deleteProject(id)}
          >
            Yes
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default ItemDeleteModal;
