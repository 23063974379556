import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";

import { Child } from "./Types";
import backendService from "../services/backend.service";

type NewFormProps = {
  project: Child;
  reset: () => void;
  toggle: () => void;
  parentID: number;
};

const ItemNewForm = ({ project, reset, toggle, parentID }: NewFormProps) => {
  const [id, setId] = useState("0");
  const [name, setName] = useState("empty");
  const [content, setContent] = useState("empty");
  const [projectInState] = useState(project);

  useEffect(() => {
    if (projectInState) {
      const { id, name, content } = projectInState;
      setId(id);
      setName(name);
      setContent(content);
    }
  }, [projectInState, setId, setName, setContent]);

  const editItem = (e: FormEvent<HTMLFormElement>) => {
    const final_name = name.length === 0 ? "no name" : name;
    const final_description = content.length === 0 ? "no description" : content;
    e.preventDefault();
    backendService
      .edit(id, final_name, final_description, parentID)
      .then(() => {
        reset();
        toggle();
      });
  };

  const createItem = (e: FormEvent<HTMLFormElement>) => {
    const final_name = name.length === 0 ? "no name" : name;
    const final_description = content.length === 0 ? "no description" : content;
    e.preventDefault();
    if (content.substring(0, 1) === "{") {
      try {
        const pasted = JSON.parse(content);
        if ("Name" in pasted) {
          backendService.create_tree(parentID, pasted).then(() => {
            reset();
            toggle();
          });
        }
      } catch (e) {}
    } else {
      backendService
        .create(id, final_name, final_description, parentID)
        .then(() => {
          reset();
          toggle();
        });
    }
  };

  return (
    <Form onSubmit={projectInState.id !== "0" ? editItem : createItem}>
      <FormGroup>
        <Label for="name">Item Name:</Label>
        <Input
          type="text"
          name="name"
          onChange={(e: ChangeEvent) => {
            const target = e.target as HTMLInputElement;
            setName(target.value);
          }}
          value={name}
        />
      </FormGroup>
      <FormGroup>
        <Label for="content">Content:</Label>
        <Input
          type="textarea"
          rows={8}
          name="content"
          onChange={(e: ChangeEvent) => {
            const target = e.target as HTMLInputElement;
            setContent(target.value);
          }}
          value={content}
        />
      </FormGroup>
      <Button>Submit</Button>
    </Form>
  );
};

export default ItemNewForm;
