import React, { Component } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Item from "./components/Item";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Item />,
  },
  {
    path: "node/:nodeId",
    element: <Item />,
  },
]);

class App extends Component {
  render() {
    return (
      <React.StrictMode>
        <RouterProvider router={router} />
      </React.StrictMode>
    );
  }
}

export default App;
