import { Fragment, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";

import ItemNewForm from "./ItemNewForm";
import { Child } from "./Types";

type NewModalProps = {
  create: boolean;
  project: Child;
  parentID: string;
  reset: () => void;
};

const ItemNewModal = ({ create, project, parentID, reset }: NewModalProps) => {
  const [modal, setModal] = useState(false);

  var title = "Edit item";
  var button = (
    <Button
      outline
      onClick={() => {
        setModal(!modal);
      }}
      style={{ minWidth: "60px" }}
      title="Edit name or description of this item"
    >
      📝
    </Button>
  );

  if (create) {
    title = "Create new item";
    button = (
      <Button
        outline
        className="float-right"
        onClick={() => {
          setModal(!modal);
        }}
        style={{ minWidth: "80px" }}
        title="Add a new item here"
      >
        ➕
      </Button>
    );
  }

  return (
    <Fragment>
      {button}
      <Modal
        isOpen={modal}
        toggle={() => {
          setModal(!modal);
        }}
      >
        <ModalHeader
          toggle={() => {
            setModal(!modal);
          }}
        >
          {title}
        </ModalHeader>

        <ModalBody>
          <ItemNewForm
            reset={reset}
            toggle={() => {
              setModal(!modal);
            }}
            project={project}
            parentID={parseInt(parentID)}
          />
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default ItemNewModal;
