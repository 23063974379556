import axios from "axios";
import {
  AUTH_API_URL,
  NODE_API_URL,
  TREE_API_URL,
  VIEW_API_URL,
} from "../constants";
import { toast } from "react-toastify";

class AuthService {
  login(
    username: string,
    password: string,
    setLoggedIn: (x: boolean) => void,
    reload: () => void
  ) {
    this.log_in(username, password)
      .then(() => {
        toast("Login successful");
        reload();
      })
      .catch((er) => {
        toast("Incorrect username or password");
        setLoggedIn(false);
      });
  }

  logout() {
    localStorage.removeItem("user");
  }

  //register(username: string, email: string, password: string) {
  //  return axios.post(AUTH_API_URL + "signup", {
  //    username,
  //    email,
  //    password
  //  });
  //}

  getConfig() {
    let bearer = "";
    let authed = authService.getCurrentUser();
    if (authed !== null) {
      if (authed.access) {
        bearer = authed.access;
      }
    }
    const config = {
      headers: {
        Authorization: `Bearer ${bearer}`,
      },
    };

    return config;
  }

  getCurrentUser() {
    const userStr = localStorage.getItem("user");
    if (userStr) return JSON.parse(userStr);
    return null;
  }

  delete(id: string) {
    return axios.delete(NODE_API_URL + id, this.getConfig());
  }

  edit(
    id: string,
    final_name: string,
    final_description: string,
    parentID: number
  ) {
    return axios.put(
      NODE_API_URL + id,
      {
        id: id,
        name: final_name,
        content: final_description,
        parent: parentID,
      },
      this.getConfig()
    );
  }

  create(
    id: string,
    final_name: string,
    final_description: string,
    parentID: number
  ) {
    return axios.post(
      NODE_API_URL + id,
      {
        id: id,
        name: final_name,
        content: final_description,
        parent: parentID,
      },
      this.getConfig()
    );
  }

  create_tree(parentID: number, pasted: JSON) {
    return axios.post(
      TREE_API_URL + parentID.toString(),
      pasted,
      this.getConfig()
    );
  }

  get(new_id: string) {
    return axios.get(VIEW_API_URL + new_id);
  }

  getTree(new_id: string) {
    return axios.get(TREE_API_URL + new_id, this.getConfig());
  }

  pasteTree(new_id: string, copied_tree: JSON) {
    return axios.post(TREE_API_URL + new_id, copied_tree, this.getConfig());
  }

  log_in(username: string, password: string) {
    return axios
      .post(
        AUTH_API_URL + "token/",
        {
          username: username,
          password: password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          //withCredentials: true,
        }
      )
      .then((response) => {
        if (response.data.access) {
          let resdata = response.data;
          resdata["name"] = username;
          localStorage.setItem("user", JSON.stringify(resdata));
        }
      });
  }
}

const authService = new AuthService();

export default authService;
