import { ChangeEvent, Fragment, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import backendService from "../services/backend.service";
import { toast } from "react-toastify";

type LoginLogoutFormState = {
  setLoggedIn: (x: boolean) => void;
  reload: () => void;
};

const LoginLogoutForm = ({ setLoggedIn, reload }: LoginLogoutFormState) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const try_login = (
    usrname: string,
    pasword: string,
    setLoggedIn: (x: boolean) => void,
    reload: () => void
  ) => {
    try {
      backendService.login(usrname, pasword, setLoggedIn, reload);
      setLoggedIn(true);
    } catch (e) {
      toast("Login failed");
      //setLoggedIn(false);
    }
  };

  return (
    <Form onSubmit={() => try_login(username, password, setLoggedIn, reload)}>
      <FormGroup>
        <Label for="username">Username:</Label>
        <Input
          type="text"
          name="username"
          onChange={(e: ChangeEvent) => {
            const target = e.target as HTMLInputElement;
            setUsername(target.value);
          }}
          value={username}
        />
      </FormGroup>

      <FormGroup>
        <Label for="password">Password:</Label>
        <Input
          type="password"
          name="password"
          onChange={(e: ChangeEvent) => {
            const target = e.target as HTMLInputElement;
            setPassword(target.value);
          }}
          value={password}
        />
      </FormGroup>
      <Button type="submit">Log in</Button>
    </Form>
  );
};

type LoginModalState = {
  loggedIn: boolean;
  setLoggedIn: (x: boolean) => void;
  reload: () => void;
};

const LoginLogOutModal = ({
  loggedIn,
  setLoggedIn,
  reload,
}: LoginModalState) => {
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const currentUser = backendService.getCurrentUser();
  const currentUserFinal =
    currentUser === null || currentUser === "null" ? "" : currentUser;

  return (
    <Fragment>
      <Button
        outline
        onClick={() => {
          if (loggedIn) {
            backendService.logout();
            setLoggedIn(false);
            toggle();
          } else toggle();
        }}
        style={{ minWidth: "60px" }}
        title={loggedIn ? "Logout" : " Login"}
      >
        {loggedIn ? currentUserFinal.name + "🔒" : "🔑"}
      </Button>

      {loggedIn ? (
        <></>
      ) : (
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>Login</ModalHeader>
          <ModalBody>
            <LoginLogoutForm setLoggedIn={setLoggedIn} reload={reload} />
          </ModalBody>
          <ModalFooter>
            <Button type="button" onClick={() => toggle()}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </Fragment>
  );
};

export default LoginLogOutModal;
