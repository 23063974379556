import { useEffect, useState } from "react";
import { Button, ButtonGroup, Container, Table } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import QRCode from "react-qr-code";
import ItemSwitchToButton from "./ItemSwitchToButton";
import ReactMarkdown from "react-markdown";
import ItemNewModal from "./ItemNewModal";
import ItemDeleteModal from "./ItemDeleteModal";
import { FRONTEND_URL } from "../constants";
import { Child, Parent } from "./Types";

import "react-toastify/dist/ReactToastify.min.css";
import React from "react";
import backendService from "../services/backend.service";
import LoginLogOutModal from "./LoginLogoutModal";

const flatten_parents = (parent: Parent, listed: Parent[]): Parent[] => {
  if (parent !== undefined) {
    if (parent.id !== "0") {
      if (parent.parent !== undefined) {
        listed = [{ id: parent.id, name: parent.name }].concat(listed);
        return flatten_parents(parent.parent, listed);
      } else return listed;
    } else return listed;
  } else return listed;
};

const ProjectNode = () => {
  let { nodeId } = useParams();
  const finalId = nodeId === undefined ? "1" : nodeId;

  const [project, setProject] = useState({
    id: finalId,
    name: "loading",
    content: "loading",
    children: [{ id: "0", name: "loading", content: "loading" }],
    parent: {
      id: "0",
      name: "",
    },
  });

  const [parent, setParent] = useState({
    id: "0",
    name: "",
  });

  const [finalNodeId, setFinalNodeId] = useState(finalId);

  let navigate = useNavigate();

  const get_project_wrapped = React.useCallback(
    (new_id: string) => {
      backendService.get(new_id).then((res) => {
        const new_project = res.data;
        setFinalNodeId(new_project.id);
        setProject(new_project);
        setParent(new_project.parent);
        navigate("/node/" + new_project.id);
      });
    },
    [setProject, setParent, setFinalNodeId, navigate]
  );

  const get_project_wrapped_finalNodeId = React.useCallback(() => {
    get_project_wrapped(finalNodeId);
  }, [finalNodeId, get_project_wrapped]);

  const export_tree = (new_id: string) => {
    backendService.getTree(new_id).then((res) => {
      if (navigator.clipboard !== undefined) {
        navigator.clipboard.writeText(JSON.stringify(res.data));
        toast("Item copied to clipboard");
      } else {
        console.log(JSON.stringify(res.data));
        toast("clipboard not available");
      }
    });
  };

  const send_tree = (new_id: string, copied_tree: JSON) => {
    backendService.pasteTree(new_id, copied_tree).then(() => {
      if (navigator.clipboard !== undefined) {
        get_project_wrapped(new_id);
        toast("Item pasted from clipboard");
      } else {
        toast("clipboard not available");
      }
    });
  };

  const parent_list: Parent[] =
    project.parent !== undefined ? flatten_parents(project.parent, []) : [];

  const getPromptForDescription = (name: string, description: string) => {
    const sub_items =
      project.children.length === 0
        ? "there are no sub-items yet"
        : "these are the other sub-items: " +
          project.children.map((c) => c.name).toString();
    const prompt =
      "You are " +
      parent_list.map((p) => "in " + p.name + " ").concat() +
      "in " +
      project.name +
      "\n \n" +
      project.name +
      " has the Description: " +
      project.content +
      "\n \n" +
      "you are sub-item: " +
      name +
      "\n \n" +
      "your current Description is: " +
      description +
      "\n \n" +
      sub_items +
      "\n \nPlease give me an improved Description for yourself";
    if (navigator.clipboard !== undefined) {
      navigator.clipboard.writeText(prompt);
      toast("Description prompt copied to clipboard");
    } else {
      console.log(prompt);
      toast("clipboard not available");
    }
  };

  const getPromptForItem = () => {
    const sub_items =
      project.children.length === 0
        ? "you have no sub-items yet"
        : "you already have these sub-items: " +
          project.children.map((c) => c.name).toString();
    const prompt =
      "You are " +
      parent_list.map((p) => "in " + p.name + " ").concat() +
      "\n \n" +
      "you are item: " +
      project.name +
      "\n \n" +
      "your Description is: " +
      project.content +
      "\n \n" +
      sub_items +
      "\n \nPlease give me a deeply structured new sub-item that fits well with the other sub-items. In recursive json format where each tree-node has a Name and a Description and may have Items which is a list of sub-nodes (recursively)";
    if (navigator.clipboard !== undefined) {
      navigator.clipboard.writeText(prompt);
      toast("Item prompt copied to clipboard");
    } else {
      console.log(prompt);
      toast("clipboard not available");
    }
  };

  useEffect(() => {
    if (project.name === "loading") {
      project.name = "still loading";
      get_project_wrapped_finalNodeId();
    }
  }, [project, get_project_wrapped_finalNodeId]);
  const itemName = project.name;
  const parentId =
    parent === undefined || parent === null || parseInt(parent.id) < 1
      ? "0"
      : parent.id;

  let bearer = "";
  let authed = backendService.getCurrentUser();
  if (authed !== null) {
    if (authed.access) {
      bearer = authed.access;
    }
  }
  const [loggedIn, setLoggedIn] = useState(bearer !== "");

  return (
    <Container style={{ marginTop: "20px" }}>
      <ToastContainer />
      <Table>
        <thead>
          <tr>
            <td colSpan={3}>
              <ButtonGroup>
                {parseInt(parentId) > 0
                  ? parent_list.map((p) => (
                      <ItemSwitchToButton
                        key={p.id}
                        item={p.id}
                        onClick={(new_id: string) => {
                          get_project_wrapped(new_id);
                        }}
                        label={
                          p.name.length < 15
                            ? p.name + " ⟵"
                            : p.name.substring(0, 12) + "... ⟵"
                        }
                      />
                    ))
                  : ""}
              </ButtonGroup>
            </td>
          </tr>
          <tr>
            <td colSpan={3}>
              <h3>{project === undefined ? "loading" : itemName}</h3>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={2}>
              <ReactMarkdown>{project.content}</ReactMarkdown>
            </td>
            <td align="right" width="240px">
              <ButtonGroup>
                <LoginLogOutModal
                  loggedIn={loggedIn}
                  setLoggedIn={setLoggedIn}
                  reload={get_project_wrapped_finalNodeId}
                />
                <Button outline>
                  <QRCode
                    size={24}
                    style={{
                      height: "24px",
                      maxWidth: "100%",
                      width: "24px",
                      margin: "auto",
                    }}
                    value={
                      FRONTEND_URL +
                      "node/" +
                      (project === undefined ? "loading" : project.id)
                    }
                    viewBox={`0 0 256 256`}
                  />
                </Button>
              </ButtonGroup>
              <div className="mt-2">
                {loggedIn ? (
                  <ButtonGroup>
                    <ItemNewModal
                      create={true}
                      reset={() => get_project_wrapped_finalNodeId()}
                      project={{
                        id: "0",
                        name: "",
                        content: "",
                      }}
                      parentID={project.id}
                    />
                    <Button
                      outline
                      style={{ minWidth: "80px" }}
                      onClick={() => {
                        getPromptForItem();
                      }}
                      title="Copy prompt for a chatbot to suggest a new item"
                    >
                      🤖
                    </Button>
                    <Button
                      outline
                      style={{ minWidth: "80px" }}
                      onClick={() => {
                        if (navigator.clipboard !== undefined) {
                          navigator.clipboard.readText().then((clipText) => {
                            try {
                              const pasted = JSON.parse(clipText);
                              if ("Name" in pasted) {
                                send_tree(project.id, pasted);
                              }
                            } catch (e) {
                              toast("Paste failed: bad format");
                            }
                          });
                        } else {
                          toast("clipboard not available");
                        }
                      }}
                      title="Paste item from clipboard"
                    >
                      📥
                    </Button>
                  </ButtonGroup>
                ) : (
                  <></>
                )}
              </div>

              {!project.children || project.children.length <= 0 ? (
                <b>No items added here yet</b>
              ) : (
                project.children.map((child: Child) => (
                  <div className="mt-2" key={child.id}>
                    <ButtonGroup vertical>
                      <ItemSwitchToButton
                        item={child.id}
                        onClick={(new_id: string) => {
                          get_project_wrapped(new_id);
                        }}
                        label={"⤷ " + child.name}
                      />
                      <Button outline>
                        {child.content.length < 100
                          ? child.content
                          : child.content.substring(0, 97) + "..."}
                      </Button>
                      {loggedIn ? (
                        <ButtonGroup>
                          <ItemNewModal
                            create={false}
                            reset={() => get_project_wrapped_finalNodeId()}
                            project={{
                              id: child.id,
                              name: child.name,
                              content: child.content,
                            }}
                            parentID="0"
                          />
                          <Button
                            outline
                            style={{ minWidth: "60px" }}
                            onClick={() => {
                              export_tree(child.id);
                            }}
                            title="Copy this item to clipboard"
                          >
                            📋
                          </Button>
                          <Button
                            outline
                            style={{ minWidth: "60px" }}
                            onClick={() => {
                              getPromptForDescription(
                                child.name,
                                child.content
                              );
                            }}
                            id="Copytreeprompt"
                            title="Copy prompt for a chatbot to suggest a better description"
                          >
                            🤖
                          </Button>
                          <ItemDeleteModal
                            id={child.id}
                            reset={() => get_project_wrapped_finalNodeId()}
                          />
                        </ButtonGroup>
                      ) : (
                        <></>
                      )}
                    </ButtonGroup>
                  </div>
                ))
              )}
            </td>
          </tr>
        </tbody>
      </Table>
    </Container>
  );
};

export default ProjectNode;
