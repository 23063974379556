import { Button } from "reactstrap";

type SwitchProps = {
  item: string;
  onClick: (id: string) => void;
  label: string;
};

const ItemSwitchToButton = ({ item, onClick, label }: SwitchProps) => {
  return (
    <Button
      color="primary"
      className="float-right"
      onClick={() => {
        onClick(item);
      }}
      style={{ minWidth: "10px" }}
      title={"Open " + label}
    >
      {label}
    </Button>
  );
};

export default ItemSwitchToButton;
